import React from "react"
import styles from "./ProgressBarTradingSteps.module.css"
import { LoadingWrapper, ProgressBar } from "../.."

interface Props {
  steps: { title: string; subtitle?: string; details?: string; icon?: any }[]
  activeStep?: number
  isLoading?: boolean
}

export const ProgressBarTradingSteps = ({ steps, activeStep, isLoading }: Props) => {
  return (
    <div className={styles.outerContainer}>
      <LoadingWrapper isLoading={isLoading} className={`${styles.title}`}>
        <span>
          {steps[activeStep]?.title}: {steps[activeStep]?.subtitle}
        </span>
      </LoadingWrapper>
      <div className={styles.container}>
        {steps.map(({ title, subtitle }, idx) => {
          const active = idx === activeStep
          return idx == 0 ? null : (
            <div className={styles.step} key={title + subtitle}>
              <ProgressBar active={active} progress={activeStep >= idx ? 100 : 0} />
              {/* {subtitle && !isLoading && (
                <div className={`${styles.subtitle}  ${active ? styles.active : ""}`}>{subtitle}</div>
              )} */}
            </div>
          )
        })}
      </div>
      <LoadingWrapper isLoading={isLoading} className={`${styles.title}`}>
        <div className={`${styles.subtitle}`}>
          {steps[activeStep]?.icon ? <span className={styles.icon}>{steps[activeStep]?.icon}</span> : null}
          {steps[activeStep]?.details}
        </div>
      </LoadingWrapper>
    </div>
  )
}
