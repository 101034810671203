import { ReactNode, useEffect, useState } from "react"

import { ClerkLoaded, RedirectToSignIn, SignedIn, SignedOut, UserButton } from "@clerk/nextjs"

import { DashboardState } from "helpers/dashboardHelper"
import DashboardLayoutNavigation from "./DashboardLayoutNavigation"
import styles from "./TwoColumnDashboardLayout.module.css"
import Layout from "../../Layout"
// import { UserBlacklistedModal } from "../../../onboarding/components/UserBlacklistedModal"
import FrontChatWidget from "../../../widgets/FrontChatWidget"

type Props = {
  state: DashboardState
  children?: undefined
  left: ReactNode
  right?: {
    content: ReactNode
    title: string
  }
}

export const TwoColumnDashboardLayout = (props: Props) => {
  const [isCypress, setIsCypress] = useState(undefined)

  useEffect(() => {
    setIsCypress(!!window.Cypress)
  }, [])

  if (isCypress === undefined) {
    return <></>
  }

  if (isCypress) {
    return (
      <Layout showHeader={false} showFooter={false}>
        <TwoColumnDashboardLayoutAuthenticated {...props} />
      </Layout>
    )
  }
  return (
    <>
      <SignedIn>
        <Layout showHeader={false} showFooter={false}>
          <TwoColumnDashboardLayoutAuthenticated {...props} />
        </Layout>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  )
}

const TwoColumnDashboardLayoutAuthenticated = ({ left, right, state }: Props) => {
  const verificationNeeded = state?.state === "IRS_REQUIRED"
  return (
    <div className={styles.container}>
      <FrontChatWidget />
      <DashboardLayoutNavigation verificationNeeded={verificationNeeded} />
      <div className={styles.content}>
        <div className={`${styles.leftContainer}`}>
          <div className={`${styles.leftContent}`}>{left}</div>
        </div>
        {right && (
          <div className={styles.rightContainer}>
            <div className={styles.rightTitle}>
              <ClerkLoaded>
                <div className="hideMobile">
                  <UserButton showName={true} />
                </div>
              </ClerkLoaded>
            </div>
            {right.content}
          </div>
        )}
      </div>
    </div>
  )
}
