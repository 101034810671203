import React from "react"
import styles from "./DetailedList.module.css"
interface Props {
  title: string
  rows: { key?: string; title: string; value: string | JSX.Element }[]
}

export const DetailedList = ({ title, rows }: Props) => {
  return (
    <div className={styles.section}>
      <h4>{title}</h4>
      <table className={styles.eventTable}>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.key ?? row.title}>
                <td>{row.title}</td>
                <td>{row.value}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
