import React from "react"
import styles from "./BarChart.module.css"
import { Space } from "../.."

interface Props {
  columns: {
    title: string
    value: number
  }[]
  height?: number
}

const colors = ["#0C171A", "#D2ED64", "#ECBE83"]

export const BarChart = ({ columns, height = 50 }: Props) => {
  const total = columns.reduce((prev, curr) => prev + curr.value, 0)
  return (
    <Space gap={15}>
      <div className={styles.chartContainer} style={{ height }}>
        {columns.map((column, id) => (
          <div
            key={column.title}
            className={styles.chartColumnContainer}
            style={{ backgroundColor: colors[id < columns.length ? id : 1], flexGrow: column.value / total }}
          />
        ))}
      </div>
      <Space direction="row" gap={40} justify="center" className={styles.legendContainer}>
        {columns.map((column, id) => (
          <Space key={column.title} direction="row" gap={12} justify="center" align="center" className={styles.legend}>
            <div className={styles.legendMarker} style={{ backgroundColor: colors[id < columns.length ? id : 1] }} />
            {column.title}
          </Space>
        ))}
      </Space>
    </Space>
  )
}
