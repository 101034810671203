import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"

import Tooltip from "rc-tooltip"
import { CSSTransition } from "react-transition-group"
import clsx from "clsx"
import RoadmapIcon from "vector/icons/financial-roadmap-icon-unselected.svg"
import RoadmapIconSelected from "vector/icons/financial-roadmap-icon.svg"
import { useFlags } from "helpers/flagHelper"
import styles from "./DashboardLayoutNavigation.module.css"
import BurgerSVG from "../../../../vector/icons/burger.svg"
import AboutUsIcon from "../../../../vector/icons/nav-about-us.svg"
import HomeIconSelected from "../../../../vector/icons/nav-home-selected.svg"
import HomeIcon from "../../../../vector/icons/nav-home.svg"
import InvestmentsIconSelected from "../../../../vector/icons/nav-investments-selected.svg"
import InvestmentsIcon from "../../../../vector/icons/nav-investments.svg"
import PlanIcon from "../../../../vector/icons/nav-plan.svg"
import PlanIconSelected from "../../../../vector/icons/nav-plan-selected.svg"
import DocumentsIconSelected from "../../../../vector/icons/nav-statements-selected.svg"
import DocumentsIcon from "../../../../vector/icons/nav-statements.svg"
import UserIconSelected from "../../../../vector/icons/nav-user-selected.svg"
import UserIcon from "../../../../vector/icons/nav-user.svg"
import LogoSVG from "../../../../vector/logo.svg"

type Props = {
  verificationNeeded: boolean
}

const DashboardLayoutNavigation = ({ verificationNeeded }: Props) => {
  const router = useRouter()
  const flags = useFlags()

  const directory = [
    { url: "/dashboard", icon: <HomeIcon />, selectedIcon: <HomeIconSelected />, title: "Home" },
    {
      url: "/dashboard/plan",
      icon: <PlanIcon />,
      selectedIcon: <PlanIconSelected />,
      title: "Plan",
    },
    {
      url: "/dashboard/assets",
      icon: <InvestmentsIcon />,
      selectedIcon: <InvestmentsIconSelected />,
      title: "Assets",
    },
    {
      url: "/dashboard/transactions",
      icon: <DocumentsIcon />,
      selectedIcon: <DocumentsIconSelected />,
      title: "Documents",
      disabled: verificationNeeded,
    },
    {
      url: "/dashboard/account",
      icon: <UserIcon />,
      selectedIcon: <UserIconSelected />,
      title: "Account",
    },
  ]
  if (flags.enableFinancialRoadmap) {
    directory.splice(4, 0, {
      url: "/dashboard/roadmap",
      icon: <RoadmapIcon />,
      selectedIcon: <RoadmapIconSelected />,
      title: "Roadmap",
      disabled: verificationNeeded,
    })
  }
  const [burgerOpen, setBurgerOpen] = useState(false)
  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const isActivePage = (href) => {
    let activeUrl = router.asPath.split("?")[0].split("#")[0]

    if (activeUrl.split("/").length === 2) {
      return href === "/dashboard"
    }
    if (activeUrl.split("/").length > 3) {
      // if the url is longer than 3 segments, it's a sub page
      activeUrl = activeUrl.split("/").slice(0, 3).join("/")
    }
    return href.includes(activeUrl)
  }

  return (
    <>
      <div
        className={clsx(styles.mobileNav, burgerOpen && styles.mobileNavOpen)}
        onClick={() => {
          setBurgerOpen(false)
        }}
      >
        <div className={styles.navBurgerWrap} style={{ backgroundColor: `rgba(0,0,0,${scroll / 100})` }}>
          <Link href="/" passHref>
            <LogoSVG className={styles.mobileLogo} />
          </Link>
          <BurgerSVG
            className={styles.burger}
            onClick={(e) => {
              e.stopPropagation()
              setBurgerOpen(!burgerOpen)
            }}
          />
        </div>
      </div>

      <CSSTransition in={burgerOpen} timeout={350} classNames={"display"} unmountOnExit>
        <div
          className={styles.burgerNav}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {directory.map((dir) => (
            <Link
              href={dir.disabled ? "#" : dir.url}
              key={dir.url}
              className={clsx(styles.navItem, isActivePage(dir.url) && styles.active, dir.disabled && styles.disabled)}
              data-cy={`dashboard-menu-nav-${dir.title}`}
            >
              {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
              <span>{dir.title}</span>
            </Link>
          ))}
        </div>
      </CSSTransition>

      <div className={styles.navBar}>
        <Link href="/" passHref className={styles.logo}>
          <LogoSVG />
        </Link>
        {directory.map((dir) => (
          <Link href={dir.disabled ? "#" : dir.url} key={dir.url} passHref legacyBehavior>
            <Tooltip
              placement="right"
              overlayInnerStyle={{ maxWidth: "none" }}
              overlay={dir.title}
              trigger={"hover"}
              overlayClassName={styles.hideTooltip}
            >
              <a
                className={clsx(
                  styles.navItem,
                  isActivePage(dir.url) && styles.active,
                  dir.disabled && styles.disabled
                )}
                data-cy={`dashboard-menu-nav-${dir.title}`}
              >
                {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
                <span>{dir.title}</span>
              </a>
            </Tooltip>
          </Link>
        ))}
        <Tooltip placement="right" overlayInnerStyle={{ maxWidth: "none" }} overlay={"Chat with us!"} trigger={"hover"}>
          <a className={styles.navItem} onClick={() => window.FrontChat("show")}>
            <AboutUsIcon />
            <span>Help</span>
          </a>
        </Tooltip>
      </div>
    </>
  )
}

export default DashboardLayoutNavigation
