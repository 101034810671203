import React, { ReactNode } from "react"
import styles from "./CardAction.module.css"

import RightArrowBox from "../../../../vector/icons/right-arrow-box.svg"

//TODO: delete this in favor of regular Card
interface Props {
  title?: string
  content: string
  accent?: boolean
  icon?: ReactNode
  onClick?: () => void
}

export const CardAction = ({ title, content, accent, icon, onClick }: Props) => {
  return (
    <div className={accent ? styles.cardAccent : styles.card} onClick={onClick}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={title ? "" : styles.contentContainerCenter}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={title ? styles.contentAsSubtitle : ""}>{content}</div>
      </div>
      <RightArrowBox className={styles.arrowIcon} />
    </div>
  )
}
