import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import clsx from "clsx"
import { useRouter } from "next/router"
import { Button } from "components/core"
import { useGeneralIssuer } from "helpers/hooks/issuers/issuerGeneralHook"
import CaretIcon from "vector/icons/caret.svg"
import WidgetInitialBg from "vector/icons/issuer-onboarding-widget-bg.svg"
import WidgetCompleteBg from "vector/icons/issuer-onboarding-widget-complete-bg.svg"
import CheckIcon from "vector/icons/check_icon.svg"
import styles from "./SetupGuideWidget.module.css"
import { SetupConfirmModal } from "./SetupConfirmModal"

export const SetupGuideWidget = () => {
  const router = useRouter()
  const { data, mutate, isValidating } = useGeneralIssuer()
  const issuerDashboardOnboardingStep = data?.issuerDashboardOnboardingStep
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const [showInitialWidgetContent, setShowInitialWidgetContent] = useState(true)

  const currentUrl = router.asPath

  const onContinue = async ({ currentTab, nextTab, isLastStep = false }) => {
    if (currentUrl === "/issuer" && issuerDashboardOnboardingStep > 1 && !isLastStep) {
      await router.push(
        {
          pathname: "/issuer/policies",
          query: { initialActiveTab: currentTab },
        },
        "/issuer/policies"
      )

      return
    }

    if (currentTab === "admin_access") {
      await router.push("/issuer")
    }

    setIsLocalLoading(true)
    await axios.post("/api/issuer/dashboard-onboarding/progress", {
      issuerDashboardOnboardingStep: Math.min(issuerDashboardOnboardingStep + 1, 7),
      isIssuerDashboardOnboarding: isLastStep ? false : true,
    })
    mutate()
    if (nextTab) {
      await router.push(
        {
          pathname: "/issuer/policies",
          query: { initialActiveTab: nextTab },
        },
        "/issuer/policies"
      )
    }
    setIsLocalLoading(false)
    if (isLastStep) {
      setShowInitialWidgetContent(false)
    }
  }

  const ListItems = [
    {
      title: "Review 10b5-1 plan policies",
    },
    {
      title: "Review trading windows",
    },
    {
      title: "Review plan documents",
    },
    {
      title: "Add special blackout list (Optional)",
    },
    {
      title: "Add admin users (Optional)",
    },
  ]

  const getWidgetContent = () => {
    if (showInitialWidgetContent && issuerDashboardOnboardingStep === 1) {
      return (
        <Fragment>
          <WidgetInitialBg />
          <div className={styles.widgetContent}>
            <div className={styles.expandedTitle}>
              Follow the guide to finish setup{" "}
              <CaretIcon
                className={styles.widgetCaret}
                onClick={() => {
                  setIsExpanded(!isExpanded)
                }}
              />{" "}
            </div>
            <div className={styles.expandedSubtitle}>
              We have configured your policies based on your Insider Policy Doc. The policies are in draft and will not
              come into effect without your approval. <br />
              <br /> You can make further adjustments as needed.
            </div>
          </div>
        </Fragment>
      )
    }

    if (issuerDashboardOnboardingStep >= 1 && issuerDashboardOnboardingStep <= 6) {
      return (
        <div className={styles.widgetContent}>
          <div className={clsx(styles.expandedTitle, styles.listTitle)}>
            Getting started
            <CaretIcon
              className={styles.widgetCaret}
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}
            />{" "}
          </div>
          <div className={styles.list}>
            {ListItems.map((item, index) => {
              return (
                <div key={item.title} className={styles.listItem}>
                  {index + 2 < issuerDashboardOnboardingStep ? (
                    <span className={styles.listItemCompleted}>
                      <CheckIcon />
                    </span>
                  ) : (
                    <span
                      className={clsx(
                        styles.listItemIndex,
                        index + 2 > issuerDashboardOnboardingStep && styles.listItemNotStarted
                      )}
                    >
                      {index + 1}
                    </span>
                  )}

                  <span className={styles.listItemTitle}>{item.title}</span>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    if (issuerDashboardOnboardingStep === 7) {
      return (
        <Fragment>
          <div className={styles.widgetContent}>
            <div className={styles.expandedTitle}>
              Setup has been successfully saved!
              <CaretIcon
                className={styles.widgetCaret}
                onClick={() => {
                  setIsExpanded(!isExpanded)
                }}
              />{" "}
            </div>
            <div className={styles.expandedAdditionalTitle}>Questions in the future?</div>
            <div className={styles.expandedSubtitle}>
              This is the end of the setup process. If you have any questions, you'll find a chat button here. Our
              support team usually responds in a few hours.
            </div>
            <WidgetCompleteBg className={styles.widgetCompleteBgIcon} />
          </div>
        </Fragment>
      )
    }
  }

  const getWidgetButton = (issuerDashboardOnboardingStep: number) => {
    switch (issuerDashboardOnboardingStep) {
      case 1:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Start finalizing policies"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "", nextTab: "10b51_policies" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 2:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Confirm 10b5-1 policies"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "10b51_policies", nextTab: "trading_windows" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 3:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Confirm trading windows"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "trading_windows", nextTab: "plan_documents" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 4:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Confirm plan documents"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "plan_documents", nextTab: "special_blackout" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 5:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Next step ->"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "special_blackout", nextTab: "admin_access" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 6:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="Complete setup"
            className={styles.expandedButton}
            onClick={async () => {
              await onContinue({ currentTab: "admin_access", nextTab: "" })
            }}
            loading={isValidating || isLocalLoading}
          />
        )
      case 7:
        return (
          <Button
            shape="round"
            type="primary"
            size="default"
            title="I'm ready to officially start"
            className={styles.expandedButton}
            onClick={() => {
              setIsExpanded(false)
              setIsConfirmModalOpen(true)
            }}
            loading={isValidating || isLocalLoading}
          />
        )
    }
  }

  useEffect(() => {
    if (currentUrl === "/issuer/policies") {
      setIsExpanded(true)
    }
  }, [currentUrl])

  return (
    <div className={styles.container}>
      {!isExpanded && !isConfirmModalOpen && (
        <Button
          shape="round"
          type="primary"
          title="Setup guide"
          size="default"
          className={styles.button}
          iconRight={<CaretIcon />}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        />
      )}

      {isExpanded && (
        <div className={styles.expandedContainer}>
          <div className={styles.initialGreenTopBar} />
          {getWidgetContent()}
          <div className={styles.expandedButtonContainer}>{getWidgetButton(issuerDashboardOnboardingStep)}</div>
        </div>
      )}
      <SetupConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => {
          setIsConfirmModalOpen(false)
        }}
        onConfirm={async () => {
          await onContinue({ isLastStep: true, currentTab: "", nextTab: "" })
        }}
        isLoading={isValidating || isLocalLoading}
      />
    </div>
  )
}
